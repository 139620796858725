<template>

  <div class="position-relative">
    <div>
      <div class="">
        <div class="bg-light">
          <div class="container py-5">
            <div class="row h-100 align-items-center py-5">
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 mt-5">
                <h3 class="display-4 font-weight-bold text-white mt-5">Blogs</h3>
                <p class="lead text-white mb-5">
                Find out more about the latest information from us
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-around" v-if="loading">
          <loader-component></loader-component>
        </div>
        <div class="container mb-5" v-if="!loading">
          <img :src="data.file_endpoint" class="img-fluid b-radius mt-5" alt="" />
          <h1 class="h1 mt-3">{{ data.title }}</h1>
          <p>
            {{ data.body }}
          </p>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import LoaderComponent from "../../components/Loader.vue";
export default {
  components: { LoaderComponent },
  name: "Detailblog",
  props: ["slug"],
  data() {
    return {
      loading: true,
      data: [],
      token: localStorage.getItem("token"),
      role: localStorage.getItem("role"),
    };
  },
  mounted() {
    if (this.slug) {
      this.getDetailBlog(this.slug);
    }
  },
  methods: {
    getDetailBlog(slug) {
      this.loading = true;
      const endpoint = "blog/detail/" + slug;
      this.$api.get(endpoint, (status, data, message) => {
        console.log(status);
        if (status == 200) {
          this.loading = false;
          this.data = data.blog;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    toLogin() {
      this.$router.push("/login");
    },

    toSubmitPackages(promotion_code) {
      this.$router.push("/customers/submit-packages/" + promotion_code);
    },
  },
};
</script>
